import React from 'react';
import {isMobile} from "react-device-detect";
import {googleScriptLoadingDelay} from "../../utils/articleUtil";
import '../../css/components/_share-toolbox.scss'

class Share extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            const headElement = document.getElementsByTagName("head")[0];
            if (!document.querySelector('script[src*="addthis_widget.js"]')) {
                const script = document.createElement("script");
                script.async = true;
                script.src = `https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5ffb02c904bafa12`;
                headElement.append(script);
            }
            try {
                window.addthis.layers.refresh();
            } catch (e) {
                console.log(e);
            }
        }, googleScriptLoadingDelay() * (isMobile ? 4 : 1));
    }

    render() {
        return <div className="addthis_inline_share_toolbox"/>;
    }
}

export default Share;
